// src/components/orders_block_title.js
import React from 'react';
import {useTranslation} from "react-i18next";

const OrdersBlockTitle = () => {
    const {t} = useTranslation();
    return (
        <div className="orders_block_title">
            <p className="orders_block_title_text bold">{t("orders_block_title_text")}</p>
        </div>
    );
};

export default OrdersBlockTitle;
