// src/components/login_block_skip.js
import React from 'react';
import {useTranslation} from "react-i18next";
import { skipLogin } from '../services/auth';

const LoginBlockSkip = () => {

    const {t} = useTranslation();

    const handleLogin = () => {
        skipLogin();
    };

    return (
        <div className="login_block_skip login_block_skip_button login_block_button button" onClick={handleLogin}>
            <div className="login_block_skip_button_text button_text button_text bold">{t("login_block_skip_text")}</div>
        </div>
    );
};

export default LoginBlockSkip;