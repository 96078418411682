import React from 'react';

const ItemDetailsBlockPrice = ({ price, oldPrice }) => (
    <div className="item_details_block_price">
        {oldPrice  ? (
            <>
                <span className="item_details_block_price_old_price line-through bold">{Number(oldPrice).toFixed(2)}</span>
                <span className="item_details_block_price_real_price bold">{Number(price).toFixed(2)}</span>
            </>
        ) : (
            <span className="item_details_block_price_real_price bold">{Number(price).toFixed(2)}</span>
        )}
    </div>


);

export default ItemDetailsBlockPrice;
