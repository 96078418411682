import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonItem = () => {
    return (
        <div className="product_catalog_block_items_item " style={{opacity: 0.5}}  >
            <Skeleton  className="product_catalog_block_items_item_image"   />
            <div className="product_catalog_block_items_item_info" style={{margin: 10}}>
                <Skeleton height={10} width={`80%`} className="product_catalog_block_items_item_name" />
                <Skeleton height={8} width={`60%`} className="product_catalog_block_items_item_short_description" />
                <Skeleton height={8} width={`40%`} className="product_catalog_block_items_item_price" />
                <Skeleton height={15} width={"100%" }   />
            </div>
        </div>
    );
};



export default SkeletonItem;
