// src/components/ProductCatalogBlockUserAccount.js
import React from 'react';
import { isLoggedIn, getUserName, getUserImage } from '../services/auth';

const ProductCatalogBlockUserAccount = () => {
    if (!isLoggedIn()) {
        return null; // Don't render anything if the user is not logged in
    }

    const userName = getUserName();
    const userImage = getUserImage(); // Assuming you have a function to get the user's image URL

    return (
        <div className="product_catalog_block_user_account ">
            {userImage && userImage != "undefined" && <img className="product_catalog_block_user_account_image" src={userImage} alt="User" />}
            <div className="product_catalog_block_user_account_username bold">{userName}</div>
        </div>
    );
};

export default ProductCatalogBlockUserAccount;
