import React, { useEffect } from 'react';
import { useAppId } from "../AppIdContext";
import { useTranslation } from "react-i18next";
import { registerUser } from '../services/api';
import { handleLoginSuccess } from '../services/auth';

const LoginBlockApple = ({ config }) => {
    const appId = useAppId();
    const iconPath = `/projects/${appId}/images/login_block_social_apple_icon.png`;
    const { t } = useTranslation();
    const client_id = t("login_block_social_apple_client_id");

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        script.onload = () => {
            window.AppleID.auth.init({
                clientId: client_id ,
                scope: 'name email',
                redirectURI: window.location.href,
                state: 'state',
                usePopup: true,
            });
        };
        document.body.appendChild(script);
    }, [client_id]);

    const handleLogin = async () => {
        try {
            const data = await window.AppleID.auth.signIn();
            const { authorization, user } = data;
            const idToken = authorization.id_token;
            const identifier = data.user ? data.user.sub : null;

            const name = user ? `${user.name.firstName} ${user.name.lastName}` : '';
            const email = user ? user.email : '';
            const method = 'apple';
            const platform = 'web';
            const response = await registerUser(name, email, null, appId, identifier, method, platform);
            const { token } = response;
            if (token) {
                handleLoginSuccess(token);
            }
        } catch (error) {
            console.error('Apple login failed', error);
        }
    };

    return (
        <div className="login_block_apple login_block_social_apple_button login_block_social_button button" onClick={handleLogin}>
            <img className="login_block_social_apple_icon login_block_social_button_icon button_icon button_icon" src={iconPath} />
            <div className="login_block_social_apple_button_text button_text button_text bold">{t("login_block_social_apple_button_text")}</div>
        </div>
    );
};

export default LoginBlockApple;
