import React from 'react';

const OrdersBlockItems = ({ items }) => {
    return (
        <div className="orders_block_items">
            {items.map((item, index) => (
                <div key={index} className="orders_block_items_item flex">
                    <div className="flex_1">
                        <div className="orders_block_items_item_name bold">{item.field_ordered_item_name[0]}</div>
                        <div className="orders_block_items_item_variant_holder flex">
                            {item.field_ordered_variant && item.field_ordered_variant.length > 0 && (
                                <div className="orders_block_items_item_variant flex_1">{item.field_ordered_variant[0]}</div>
                            )}
                        </div>
                        <div className="orders_block_items_item_addons_holder">
                            {item.field_ordered_addons && item.field_ordered_addons.length > 0 ? (
                                item.field_ordered_addons.map((addon, idx) => (
                                    <div key={idx} className="orders_block_items_item_addon flex_1">{addon}</div>
                                ))
                            ) : (
                                <div className="orders_block_items_item_addon flex_1">No Addons</div>
                            )}
                        </div>
                    </div>
                    <div className="">
                        <div className="orders_block_items_item_price">${item.field_ordered_price[0]}</div>
                        <div className="flex">
                            <div className="orders_block_items_item_quantity orders_block_items_item_quantity_label orders_block_items_item_quantity_label_text">Q:</div>
                            <div className="orders_block_items_item_quantity">{item.field_ordered_quantity[0]}</div>
                        </div>
                        <div className="orders_block_items_item_total">${(item.field_ordered_price[0] * item.field_ordered_quantity[0]).toFixed(2)}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrdersBlockItems;
