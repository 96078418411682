import React from 'react';
import { format } from 'date-fns';

const OrdersBlockHeader = ({ order }) => {
    const formattedDate = format(new Date(order.created * 1000), 'd/MM/yyyy HH:mm');

    return (
        <div className="orders_block_header">
            <div className="flex">
                <div className="orders_block_header_number orders_block_header_value orders_block_header_total bold flex_1">
                    #{order.id}
                </div>
            </div>
            <div className="flex">
                <div className="orders_block_header_date flex_1">{formattedDate}</div>
                <div className="orders_block_header_status bold">{order.status}</div>
            </div>
        </div>
    );
};

export default OrdersBlockHeader;
