import React from 'react';
import {useAppId} from "../AppIdContext";
import {useTranslation} from "react-i18next";

const CheckoutBlockConfirmation = ({ orderNumber }) => {
    const appId = useAppId();
    const iconPath = `/projects/${appId}/images/checkout_block_confirmation_icon.png`;
    const {t} = useTranslation();

    return (
    <div className="checkout_block_confirmation mode-confirm">
        <div className="checkout_block_confirmation_holder">
            <img className="checkout_block_confirmation_icon"
                 src={iconPath} />
            <div className="checkout_block_confirmation_text checkout_block_confirmation_number bold">
                {t("checkout_block_confirmation_text")}
            </div>
            <div className="checkout_block_confirmation_order_number">
                <div
                    className="checkout_block_confirmation_order_number_title checkout_block_confirmation_order_number_title_text bold">
                    {t("checkout_block_confirmation_order_number_title_text")}
                </div>
                <div className="checkout_block_confirmation_order_number_number bold">
                    #{orderNumber}
                </div>
            </div>
        </div>
    </div>
    );
};

export default CheckoutBlockConfirmation;
