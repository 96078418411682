import React, { useState, useEffect } from 'react';
import CartItem from './cart_block_items_item';
import cart from '../services/cart';
import {useAppId} from "../AppIdContext";

const CartBlockItems = () => {
    const appId = useAppId();
    const [items, setItems] = useState(cart.getItems());

    const updateCart = () => {
        setItems(cart.getItems());
    };

    useEffect(() => {
        cart.subscribe(updateCart);
        return () => cart.unsubscribe(updateCart);
    }, []);


    if (items.length == 0) {
        return (
            <div className="no_content address_block_addresses_no_addresses">
                No items in cart
            </div>
        );
    }

    return (
        <div className="cart_block_items">
            {items.map(item => (
                <CartItem key={item.id} item={item} onQuantityChange={updateCart} appId={appId} />
            ))}
        </div>
    );
};

export default CartBlockItems;
