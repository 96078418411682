import React, {useEffect, useState} from 'react';
import ProductCatalogItem from './product_catalog_block_items_item';
import {useAppId} from "../AppIdContext";
import {fetchItems} from "../services/api";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import SkeletonList from '../skeletons/SkeletonList';


const ProductCatalogBlockItems = ({ config, selectedCategoryId }) => {


    const appId = useAppId();
    const [items, setItems] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const loadItems = async () => {
            try {
                if (selectedCategoryId) {
                    setItems(null);
                    const loadedItems = await fetchItems(selectedCategoryId, appId);
                    setItems(loadedItems);
                }

            } catch (error) {
                console.error('Failed to load items:', error);
            }
        };

        loadItems();
    }, [selectedCategoryId]);


    if (items == null ) {
        return (<SkeletonList count={6} />); // Show a loading state while configuration or data is being loaded
    }


    if (items.length == 0) {
        return (
            <div className="no_content">
                {t("product_catalog_layout_no_items_message")}
            </div>
        );
    }

    return (
        <div className="product_catalog_block_items">
            {items.map(item => (

                <ProductCatalogItem
                    config={config}
                    key={item.id}
                    item={item}
                    appId={appId}
                />

            ))}
        </div>
    );
}

export default ProductCatalogBlockItems;
