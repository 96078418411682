// AppIdContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import domainMapping from './domain_mapping.json';

// Create the context
const AppIdContext = createContext();

// Create a provider component
export const AppIdProvider = ({ children }) => {
    const [appId, setAppId] = useState(null);

    useEffect(() => {
        const hostname = window.location.hostname;
        const appId = domainMapping[hostname] || domainMapping["default"];
        setAppId(appId);
    }, []);

    if (appId === null) {
        return <div>Loading...</div>; // Show a loading state while appId is being determined
    }

    return (
        <AppIdContext.Provider value={appId}>
            {children}
        </AppIdContext.Provider>
    );
};

// Create a custom hook to use the AppIdContext
export const useAppId = () => useContext(AppIdContext);
