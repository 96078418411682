import React, { useState, useEffect } from 'react';
import CheckoutItem from './checkout_block_items_item';
import cart from '../services/cart';
import {useAppId} from "../AppIdContext";

const CheckoutBlockItems = () => {
    const appId = useAppId();
    const [items, setItems] = useState(cart.getItems());

    const updateCheckout = () => {
        setItems(cart.getItems());
    };

    useEffect(() => {
        cart.subscribe(updateCheckout);
        return () => cart.unsubscribe(updateCheckout);
    }, []);

    return (
        <div className="checkout_block_items">
            {items.map(item => (
                <CheckoutItem key={item.id} item={item} onQuantityChange={updateCheckout} appId={appId} />
            ))}
        </div>
    );
};

export default CheckoutBlockItems;
