import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAddresses } from '../services/api';
import {useTranslation} from "react-i18next";
import {useAppId} from "../AppIdContext";
import Skeleton from "react-loading-skeleton";

const AddressBlockAddresses = () => {
    const [addresses, setAddresses] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appId = useAppId();

    const locationIcn = `/projects/${appId}/images/address_block_addresses_item_icon.png`;

    useEffect(() => {
        const loadAddresses = async () => {
            try {
                const addressesData = await fetchAddresses(appId);
                setAddresses(addressesData);
            } catch (error) {
                console.error('Failed to load addresses:', error);
            }
        };

        loadAddresses();
    }, []);

    const handleAddressClick = (address) => {
        navigate('/address/form', { state: { address: address , destination: '/address'}  });
    };


    const renderLoadingSkeletons = () => (
        <div className="address_block_addresses">
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
        </div>
    );

    if (!addresses) {
        return renderLoadingSkeletons(); // Show a loading state while configuration or data is being loaded
    }

    if (addresses.length == 0) {
        return (
            <div className="no_content address_block_addresses_no_addresses">
                {t("address_block_addresses_no_addresses_message")}
            </div>
        );
    }

    return (
        <div className="address_block_addresses">
            {addresses.map((address) => (
                <div
                    key={address.id}
                    className="address_block_addresses_item"
                    onClick={() => handleAddressClick(address)}
                >
                    <img
                        className="address_block_addresses_item_icon"
                        src={locationIcn}
                        alt="Address Icon"
                    />
                    <div className="address_block_addresses_item_line_holder">
                        <div className="address_block_addresses_item_line">{address.flat} {address.building} {address.street}</div>
                        <div className="address_block_addresses_item_line">{address.postal_code} {address.area} {address.city}</div>
                    </div>
                    {address.default && (
                        <div className="address_block_addresses_item_default">{t("address_block_addresses_item_default_text")}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AddressBlockAddresses;
