
import React from 'react';
import {useTranslation} from "react-i18next";

const CheckoutBlockConfirmButton = ({ onConfirmOrder }) => {
    const {t} = useTranslation();
    return (
        <div className="checkout_block_confirm_button button" onClick={onConfirmOrder}>
            <div className="checkout_block_confirm_button_text bold button_text" >{t("checkout_block_confirm_button_text")}</div>
        </div>
    );
};

export default CheckoutBlockConfirmButton;
