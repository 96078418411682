import React, { useEffect, useState } from 'react';
import { fetchLoyaltyItems } from '../services/api';
import { useAppId } from "../AppIdContext";
import cart from "../services/cart"; // Adjust the import path as necessary

const ProductCatalogBlockLoyaltyItems = ({ userCredits }) => {


    const [items, setItems] = useState([]);
    const [actualUserCredits, setActualUserCredits] = useState(userCredits);

    const appId = useAppId();

    useEffect(() => {
        fetchLoyaltyItems(appId).then(data => setItems(data));

        const handleCartChange = () => {
            // Update actual user credits when the cart changes
            setActualUserCredits(cart.getActualUserCredits(userCredits));
            // Trigger a re-render
            setItems(prevItems => [...prevItems]);
        };

        cart.subscribe(handleCartChange);
        return () => {
            cart.unsubscribe(handleCartChange);
        };
    }, [appId, userCredits]);

    const handleRedeem = (item, points) => {
        const cartItem = {
            ...item,
            points: points,
            quantity: 1
        };
        cart.addItem(cartItem);
    };

    return (
        <div className="product_catalog_block_loyalty_items">
            {items.map(item => {
                const isRedeemed = cart.getItemQuantity(item) > 0;
                const canRedeem = parseFloat(item.loyalty_points) <= actualUserCredits;
                const itemClass = canRedeem ? "" : "item-unredeemable";
                return (
                    <div
                        key={item.id}
                        className={`product_catalog_block_loyalty_items_item ${itemClass}`}
                        style={{ opacity: canRedeem ? 1 : 0.6 }}
                    >
                        <img
                            className="product_catalog_block_loyalty_items_item_image"
                            src={item.item_image[0].path}
                            alt={item.item_image[0].info.alt || item.name}
                        />
                        <div className="product_catalog_block_items_item_info">
                            <div className="product_catalog_block_loyalty_items_item_name bold">{item.name}</div>
                            <div className="product_catalog_block_loyalty_items_item_short_description">{item.short_description}</div>
                            <div className="product_catalog_block_loyalty_items_item_points bold">{item.loyalty_points}</div>
                            {isRedeemed ? (
                                <div className="product_catalog_block_loyalty_items_item_redeem product_catalog_block_loyalty_items_item_redeemed_background bold">Redeemed</div>
                            ) : (
                                <div
                                    className={`product_catalog_block_loyalty_items_item_redeem pointer bold ${canRedeem ? "" : "disabled"}`}
                                    onClick={canRedeem ? () => handleRedeem(item, item.loyalty_points) : undefined}
                                >
                                    Redeem
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProductCatalogBlockLoyaltyItems;
