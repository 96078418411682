import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonCategoryItem = () => {
    return (
        <div className="product_catalog_block_categories_item"  >
            <div className="product_catalog_block_categories_item_content p10">
                <Skeleton height={50} width={50} className="product_catalog_block_categories_item_icon" />
                <Skeleton height={10} width={80} className="product_catalog_block_categories_item_name" />
            </div>
        </div>
    );
};



export default SkeletonCategoryItem;
