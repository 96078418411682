import React, { useState, useEffect } from 'react';
import ComponentRenderer from "../utils/ComponentRenderer";
import {useAppId} from "../AppIdContext";
const Address = () => {
    const [addressPlacements, setPlacements] = useState(null);
    const appId = useAppId();
    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/address_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);


    const data = {
        block_back: {
            appId: appId,
        },
    };

    if (!addressPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(addressPlacements, data);

    return (
        <div className="address_layout">
            <div className="address_layout_toolbar toolbar">
                <div className="address_layout_header_position_header_start toolbar_start">{renderer.renderComponents('address_layout_header_position_header_start')}</div>
                <div className="address_layout_header_position_header_center toolbar_center">{renderer.renderComponents('address_layout_header_position_header_center')}</div>
                <div className="address_layout_header_position_header_end toolbar_end">{renderer.renderComponents('address_layout_header_position_header_end')}</div>
            </div>
            <div className="address_layout_content_position_content address_layout_content">{renderer.renderComponents('address_layout_content_position_content')}</div>
            <div className="address_layout_footer_holder">
                <div className="address_layout_footer">
                    <div className="address_layout_footer_positions">
                        <div className="address_layout_footer_position_footer_start">{renderer.renderComponents('address_layout_footer_position_footer_start')}</div>
                        <div className="address_layout_footer_position_footer_center">{renderer.renderComponents('address_layout_footer_position_footer_center')}</div>
                        <div className="address_layout_footer_position_footer_end">{renderer.renderComponents('address_layout_footer_position_footer_end')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Address;
