// components/ProductCatalogBlockListingGroup.js
import React, { useEffect, useState } from 'react';
import { fetchListings } from '../services/api';
import {useAppId} from "../AppIdContext";
import ProductCatalogItem from "./product_catalog_block_items_item";

const ProductCatalogBlockListingGroup = ({config}) => {
    const [listings, setListings] = useState([]);
    const appId = useAppId();

    useEffect(() => {
        const getListings = async () => {
            try {
                const data = await fetchListings(appId);
                setListings(data);
            } catch (error) {
                console.error("Error fetching listings:", error);
            }
        };

        getListings();
    }, [appId]);

    return (
        <div className="product_catalog_block_listing_group">
            {listings.map(listing => (
                <div key={listing.id}>
                    <div className="product_catalog_block_listing_group_list_title bold">{listing.name}</div>
                    <div className="product_catalog_block_listing_group_list grid">
                        {listing.list_items.map(item => (
                            <ProductCatalogItem
                                config={config}
                                key={item.id}
                                item={item}
                                appId={appId}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProductCatalogBlockListingGroup;
