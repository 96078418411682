// utils/configLoader.js

const configLoader = async (appId) => {
    try {
        const config = await import(`../projects/${appId}/config.json`);
        return config.default;
    } catch (error) {
        console.error('Error loading config:', error);
        throw new Error('Configuration not found');
    }
};

export default configLoader;
