// src/components/cart_block_title.js
import React from 'react';
import {useTranslation} from "react-i18next";

const CartBlockTitle = () => {
    const {t} = useTranslation();
    return (
        <div className="cart_block_title">
            <p className="cart_block_title_text bold">{t("cart_block_title_text")}</p>
        </div>
    );
};

export default CartBlockTitle;
