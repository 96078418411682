import React, { useState, useEffect } from 'react';
import ComponentRenderer from "../utils/ComponentRenderer";
import cart from "../services/cart";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {useAppId} from "../AppIdContext";
import {toast, ToastContainer} from "react-toastify";

const ItemDetails = ({ config }) => {
    const appId = useAppId();
    const { state } = useLocation();
    const { item } = state || {};
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [selectedVariation, setSelectedVariation] = useState(null);
    const [selectedAddOns, setSelectedAddOns] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(item.sale_price > 0 ? item.sale_price : item.base_price);
    const [oldPrice, setOldPrice] = useState(item.sale_price > 0 ? item.base_price : null);
    const [itemDetailsPlacements, setPlacements] = useState(null);

    const [selectedSlots, setSelectedSlots] = useState([]);
    const [isSlotSelectionRequired, setIsSlotSelectionRequired] = useState(item.field_item_availability_required === 1);


    const notify_error = (message) => toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/item_details_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);


    useEffect(() => {
        if (item.sale_price) {
            setPrice(item.sale_price);
        }
    }, [item.sale_price]);

    const handleVariationSelect = (groupIndex, option) => {
        const group = item.field_item_variation_group[groupIndex];
        if (group.field_variant_base_price[0] === "1") {
            setPrice(option.price);
            setSelectedVariation({ ...selectedVariation, [groupIndex]: option });
        } else {
            setSelectedAddOns((prevAddOns) => {
                const newAddOns = [...prevAddOns];
                const addOnIndex = newAddOns.findIndex(addOn => addOn.groupIndex === groupIndex);
                if (addOnIndex > -1) {
                    newAddOns[addOnIndex] = { groupIndex, name: option.name, price: option.price };
                } else {
                    newAddOns.push({ groupIndex, name: option.name, price: option.price });
                }
                return newAddOns;
            });
        }
    };


    const handleAavailabiltySelect = (slots, required, basePrice, currentBasePrice) => {
        setSelectedSlots(slots);
        setIsSlotSelectionRequired(required);
        console.log(required);
        if (basePrice) {
            setPrice(currentBasePrice);
        }
    };

    const handleAvailabilityRequired = (required) => {
        console.log(required);
        setIsSlotSelectionRequired(required);
    };

    const handleAddToCart = () => {

        for (let i = 0; i < item.field_item_variation_group.length; i++) {
            const group = item.field_item_variation_group[i];
            const isRequired = group.field_variant_required && group.field_variant_required[0] === "1";
            const isBasePrice = group.field_variant_base_price && group.field_variant_base_price[0] === "1";
            const isMultiple = group.field_variant_multiple && group.field_variant_multiple[0] === "1";
            const groupName = group.field_variant_group_name && group.field_variant_group_name[0];
            if (isRequired && isBasePrice && selectedVariation == null && group.field_variants?.length > 0) {
                notify_error(t("item_details_block_variants_error_text") + "  " + (groupName || "this group"));
                return;
            }

            if (isRequired && !selectedAddOns[i] && !isBasePrice && group.field_variants?.length > 0) {
                notify_error(t("item_details_block_variants_error_text") + "  " + (groupName || "this group"));
                return;
            }

            if (isSlotSelectionRequired && selectedSlots.length === 0) {
                notify_error("Time slot is required");
                return;
            }
        }

        const cartItem = {
            ...item,
            price: price,
            selectedVariation: selectedVariation,
            selectedAddOns: selectedAddOns,
            selectedSlots: selectedSlots,
            quantity: quantity
        };

        console.log(cartItem);



        if (config.global_single_cart_item_enable) {
            cart.clearCart();
        }

        cart.addItem(cartItem);
        if (config.item_details_block_add_to_cart_fast_checkout){
            navigate('/cart');
        } else {
            navigate('/catalog');
        }

    };

    const handleQuantityChange = (change) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + change));
    };

    const handleQuantityIncrease = () => {
        handleQuantityChange(1);
    };

    const handleQuantityDecrease = () => {
        handleQuantityChange(-1);
    };

    const data = {
        item_details_block_name: {
            name: item.name,
        },
        item_details_block_specs: {
            specs: item.field_specs,
        },
        item_details_block_short_description: {
            shortDescription: item.short_description,
        },
        item_details_block_variants_groups: {
            variationGroups: item.field_item_variation_group,
            onVariationSelect: handleVariationSelect,

        },
        item_details_block_price: {
            price: price,
            oldPrice: oldPrice,
        },
        item_details_block_availability: {
            item: item,
            onAavailabiltyChange: handleAavailabiltySelect,
            availabilityRequired: handleAvailabilityRequired

        },
        item_details_block_images: {
            images: item.item_image,
        },
        item_details_block_add_to_cart: {
            appId: appId,
            onAddToCart: handleAddToCart,
        },
        item_details_block_quantity: {
            quantity: quantity,
            onIncrease: handleQuantityIncrease,
            onDecrease: handleQuantityDecrease,
        },
    };

    if (!itemDetailsPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(itemDetailsPlacements, data);

    return (
        <div className="item_details_layout ">
            <div className="item_details_layout_toolbar toolbar">
                <div className="item_details_layout_header_position_header_start toolbar_start">{renderer.renderComponents('item_details_layout_header_position_header_start')}</div>
                <div className="item_details_layout_header_position_header_center toolbar_center">{renderer.renderComponents('item_details_layout_header_position_header_center')}</div>
                <div className="item_details_layout_header_position_header_end toolbar_end">{renderer.renderComponents('item_details_layout_header_position_header_end')}</div>
            </div>
            <div className="item_details_layout_header item_details_layout_header_position_featured">{renderer.renderComponents('item_details_layout_header_position_featured')}</div>
            <div className="item_details_layout_content">
                <div className="item_details_layout_content_row_top flex">
                    <div className="item_details_layout_content_row_top_position_start">{renderer.renderComponents('item_details_layout_content_row_top_position_start')}</div>
                    <div className="item_details_layout_content_row_top_position_end">{renderer.renderComponents('item_details_layout_content_row_top_position_end')}</div>
                </div>
                <div className="item_details_layout_content_row_middle flex">
                    <div className="item_details_layout_content_row_middle_position_start">{renderer.renderComponents('item_details_layout_content_row_middle_position_start')}</div>
                    <div className="item_details_layout_content_row_middle_position_end">{renderer.renderComponents('item_details_layout_content_row_middle_position_end')}</div>
                </div>
                <div className="item_details_layout_content_row_bottom flex">
                    <div className="item_details_layout_content_row_bottom_position_start">{renderer.renderComponents('item_details_layout_content_row_bottom_position_start')}</div>
                    <div className="item_details_layout_content_row_bottom_position_end">{renderer.renderComponents('item_details_layout_content_row_bottom_position_end')}</div>
                </div>
            </div>
            {config.item_details_layout_footer_enable && (
            <div className="item_details_layout_footer_holder">
                <div className="item_details_layout_footer">
                    <div className="item_details_layout_footer_positions">
                        <div className="item_details_layout_footer_position_footer_start">{renderer.renderComponents('item_details_layout_footer_position_footer_start')}</div>
                        <div className="item_details_layout_footer_position_footer_center">{renderer.renderComponents('item_details_layout_footer_position_footer_center')}</div>
                        <div className="item_details_layout_footer_position_footer_end">{renderer.renderComponents('item_details_layout_footer_position_footer_end')}</div>
                    </div>
                </div>
            </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default ItemDetails;
