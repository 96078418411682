import React, {useEffect, useState} from 'react';
import cart from '../services/cart';
import {useTranslation} from "react-i18next";

const CartBlockTotal = () => {

    const { t } = useTranslation();

    const [subtotal, setSubtotal] = useState(cart.getSubtotal());

    useEffect(() => {
        // Update the cart info whenever the cart changes
        const updateCartInfo = () => {
            setSubtotal(cart.getSubtotal());
        };

        cart.subscribe(updateCartInfo);

        return () => {
            cart.unsubscribe(updateCartInfo);
        };
    }, []);

    return (
        <div className="cart_block_total">
            <div class="cart_block_total_label cart_block_total_label_text">{t("cart_block_total_label_text")} </div>
            <div class="cart_block_total_total bold">{subtotal.toFixed(2)}</div>
        </div>


    );
};

export default CartBlockTotal;
