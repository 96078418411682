import React, { useState, useEffect } from 'react';
import ComponentRenderer from "../utils/ComponentRenderer";
import {useAppId} from "../AppIdContext";
const Cart = ({ config }) => {
    const [cartPlacements, setPlacements] = useState(null);
    const appId = useAppId();
    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/cart_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);


    const data = {
        block_back: {
            appId: appId,
        },
        cart_block_items: {
            appId: appId,
        },
    };

    if (!cartPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(cartPlacements, data);

    return (
        <div className="cart_layout">
            <div className="cart_layout_toolbar toolbar">
                <div className="cart_layout_header_position_header_start toolbar_start">{renderer.renderComponents('cart_layout_header_position_header_start')}</div>
                <div className="cart_layout_header_position_header_center toolbar_center">{renderer.renderComponents('cart_layout_header_position_header_center')}</div>
                <div className="cart_layout_header_position_header_end toolbar_end">{renderer.renderComponents('cart_layout_header_position_header_end')}</div>
            </div>
            <div className="cart_layout_content_position_content cart_layout_content">{renderer.renderComponents('cart_layout_content_position_content')}</div>
            {config.cart_layout_footer_enable && (
            <div className="cart_layout_footer_holder">
                <div className="cart_layout_footer">
                    <div className="cart_layout_footer_positions">
                        <div className="cart_layout_footer_position_footer_start">{renderer.renderComponents('cart_layout_footer_position_footer_start')}</div>
                        <div className="cart_layout_footer_position_footer_center">{renderer.renderComponents('cart_layout_footer_position_footer_center')}</div>
                        <div className="cart_layout_footer_position_footer_end">{renderer.renderComponents('cart_layout_footer_position_footer_end')}</div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
};

export default Cart;
