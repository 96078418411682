import React, { useEffect, useState } from 'react';
import ComponentRenderer from "../utils/ComponentRenderer";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppId } from "../AppIdContext";

const LoginScreen = ({ config }) => {
    const appId = useAppId();
    const { state } = useLocation();
    const { item } = state || {};
    const { t } = useTranslation();
    const [loginPlacements, setPlacements] = useState(null);

    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/login_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);

    if (!loginPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const data = {
        login_block_social: {
            config,
        },
    };

    const renderer = new ComponentRenderer(loginPlacements, data);

    const backgroundPath = `/projects/${appId}/images/login_block_background.png`;

    // Create background style object
    const login_layout_background = config.login_block_background_enable ? {
        background: `url(${backgroundPath}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    } : {};

    return (
        <div className="login_layout"  style={login_layout_background}>
            <div className="login_layout_position_top">{renderer.renderComponents('login_layout_position_top')}</div>
            <div className="login_layout_position_content ">{renderer.renderComponents('login_layout_position_content')}</div>
            <div className="login_layout_position_bottom">{renderer.renderComponents('login_layout_position_bottom')}</div>
        </div>
    );
};

export default LoginScreen;
