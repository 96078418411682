import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

const CheckoutBlockItemsItem = ({ item }) => {

    const {t} = useTranslation();

    const formatVariationOrAddon = (name, price) => (
        <div className="checkout_block_items_item_variant">
            <div className="checkout_block_items_item_variant_name">{name}</div>
            <div className="checkout_block_items_item_variant_price">{price}</div>
        </div>
    );

    console.log(item);

    const variations = item.selectedVariation && typeof item.selectedVariation === 'object'
        ? Object.values(item.selectedVariation).map(variation =>
            formatVariationOrAddon(variation.name, variation.price)
        )
        : null;

    // Check if selectedAddOns is an array before mapping
    const addons = Array.isArray(item.selectedAddOns)
        ? item.selectedAddOns.map(addon =>
            formatVariationOrAddon(addon.name, addon.price)
        )
        : null;

    const slots = Array.isArray(item.selectedSlots)
        ? item.selectedSlots.map(slot =>
            formatVariationOrAddon(slot.value, slot.price)
        )
        : null;


    return (
        <div className="checkout_block_items_item">
            <div className="checkout_block_items_item_details">
                <div className="checkout_block_items_item_name_holder">
                    <div className="checkout_block_items_item_name bold">{item.name}</div>
                </div>
                <div className="checkout_block_items_item_variants checkout_block_items_item_variant_holder">
                    {variations}
                </div>
                <div className="checkout_block_items_item_addons checkout_block_items_item_addons_holder">
                    {addons}
                </div>
                <div className="checkout_block_items_item_addons checkout_block_items_item_addons_holder">
                    {slots}
                </div>
            </div>

            {item.price ? (
            <div className="checkout_block_items_item_info">
                <div className="checkout_block_items_item_price bold">{item.price}</div>

                <div className="checkout_block_items_item_quantity_holder">
                    <div className="checkout_block_items_item_quantity_label checkout_block_items_item_quantity bold">{t("checkout_block_items_item_quantity_label_text")}</div>
                    <div className="checkout_block_items_item_quantity">{item.quantity}</div>
                </div>

                <div className="checkout_block_items_item_total bold">{item.price * item.quantity}</div>
            </div>
            ) : (
                <div className="checkout_block_items_item_info">
                    <div className="checkout_block_items_item_price bold">{item.points}</div>
                </div>
            )}
        </div>
    );
};

export default CheckoutBlockItemsItem;

