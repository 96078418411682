import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const CheckoutBlockConfirmationContinueButton = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();


    const handleContinueShopping = () => {
        navigate('/catalog');
    };

    return (
        <div className="checkout_block_confirmation_continue_button button" onClick={handleContinueShopping}>
            <div className="checkout_block_confirmation_continue_button_text button_text bold">
                {t("checkout_block_confirmation_continue_button_text")}
            </div>
        </div>
    );
};

export default CheckoutBlockConfirmationContinueButton;
