import React from 'react';
import SkeletonItem from './SkeletonItem';

const SkeletonList = ({ count }) => {
    return (
        <div className="product_catalog_block_items">
            {Array(count)
                .fill()
                .map((_, index) => (
                    <SkeletonItem key={index} />
                ))}
        </div>
    );
};

export default SkeletonList;
