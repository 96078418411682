import React from 'react';
import componentMapper from './componentMapper';

class ComponentRenderer {
    constructor(config, data) {
        this.config = config;
        this.data = data;
    }

    renderComponents(placementName) {
        const placements = this.config[placementName];
        if (!placements) return null;

        return placements.map((componentName, index) => {

            const Component = componentMapper[componentName];
            if (!Component) return null;

            const componentProps = this.data[componentName] || {};
            return <Component key={index} {...componentProps} />;
        });
    }
}

export default ComponentRenderer;
