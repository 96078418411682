import React from 'react';
import {useAppId} from "../AppIdContext";

const Splash_block_logo = () => {
    const appId = useAppId();
    const logoPath = `/projects/${appId}/images/splash_block_logo.png`;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    return (
        <div className="splash_block_logo" style={style}>
            <img className="splash_block_logo" src={logoPath} alt="Logo" />
        </div>
    );
};

export default Splash_block_logo;
