import React from 'react';

const ProductCatalogBlockOverlayTitle = ({selectedCategoryName}) => {
    return (
        <div className="product_catalog_block_overlay_title bold">
            {selectedCategoryName}
        </div>
    );
};

export default ProductCatalogBlockOverlayTitle;
