import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Item_details_block_images = ({ images }) => {
    // Provide a default empty array if images is null or undefined
    const validImages = images || [];

    const settings = {
        dots: validImages.length > 1,
        infinite: validImages.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="item_details_block_images">
            <Slider {...settings}>
                {validImages.map((image, index) => (
                    <div key={index}>
                        <img className="item_details_block_images_image" src={image.path} alt={`Slide ${index}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Item_details_block_images;
