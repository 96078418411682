// src/components/login_block_signup.js
import React from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const LoginBlockSignup = () => {
    const {t} = useTranslation();
    return (
        <div className="login_block_signup">
            <div className="login_block_signup_question login_block_signup_question_text">{t("login_block_signup_question_text")}</div>
            <div className="login_block_signup_action login_block_signup_action_text bold"> <Link to="/register">{t("login_block_signup_action_text")}</Link> </div>
        </div>
    );
};

export default LoginBlockSignup;
