import React, { useState } from 'react';
import { useAppId } from "../AppIdContext";
import { handleRegisterSuccess } from '../services/auth';
import { registerUser } from '../services/api';
import { useTranslation } from "react-i18next";

const LoginBlockRegister = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const appId = useAppId();
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await registerUser(name, email, password, appId, email, 'mail', 'web');
            const { token, name: userName, code, message } = response;
            if (token) {
                handleRegisterSuccess(token, userName); // Redirect to splash screen or handle successful login
            } else if (code == 0) {
                console.log(code);
                setError(message || t("login_block_mail_error"));
            }
        } catch (error) {
            console.log(error);
            setError(t("login_block_mail_error"));
        }
    };

    return (
        <div className="login_block_register">
            <form onSubmit={handleSubmit}>
                <input
                    className="login_block_register_input"
                    type="text"
                    placeholder={t("login_block_register_input_name_hint")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <input
                    className="login_block_register_input"
                    type="email"
                    placeholder={t("login_block_register_input_email_hint")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    className="login_block_register_input"
                    type="password"
                    placeholder={t("login_block_register_input_password_hint")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" className="login_block_register_button bold">{t("login_block_register_button_text")}</button>
                {error && <div className="login_block_register_error bold">{error}</div>}
            </form>
        </div>
    );
};

export default LoginBlockRegister;
