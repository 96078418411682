import React from 'react';
import {useTranslation} from "react-i18next";

const CheckoutBlockSummary = ({ summary }) => {
    const {t} = useTranslation();
    return (
        <div className="checkout_block_summary">
            <div className="checkout_block_summary_subtotal_holder checkout_block_summary_holder">
                <div className="checkout_block_summary_subtotal_text checkout_block_summary_subtotal">{t("checkout_block_summary_subtotal_text")}</div>
                <div className="checkout_block_summary_subtotal">{summary.subtotal.toFixed(2)}</div>
            </div>
            <div className="checkout_block_summary_discount_holder checkout_block_summary_holder">
                <div className="checkout_block_summary_discount_text checkout_block_summary_discount">{t("checkout_block_summary_discount_text")}</div>
                <div className="checkout_block_summary_discount">{summary.discount.toFixed(2)}</div>
            </div>
            <div className="checkout_block_summary_tax_holder checkout_block_summary_holder">
                <div className="checkout_block_summary_tax_text checkout_block_summary_tax">{t("checkout_block_summary_tax_text")}</div>
                <div className="checkout_block_summary_tax">{summary.tax.toFixed(2)}</div>
            </div>
            <div className="checkout_block_summary_service_fee_holder checkout_block_summary_holder">
                <div className="checkout_block_summary_service_fee_text checkout_block_summary_service_fee">{t("checkout_block_summary_service_fee_text")}</div>
                <div className="checkout_block_summary_service_fee">{summary.serviceFee.toFixed(2)}</div>
            </div>
            <div className="checkout_block_summary_total_holder checkout_block_summary_holder">
                <div className="checkout_block_summary_total_text checkout_block_summary_total bold">{t("checkout_block_summary_total_text")}</div>
                <div className="checkout_block_summary_total bold">{summary.total.toFixed(2)}</div>
            </div>
        </div>
    );
};

export default CheckoutBlockSummary;
