import React, { useState, useEffect } from 'react';
import cart from '../services/cart';
import { useNavigate } from 'react-router-dom';
import {useAppId} from "../AppIdContext";
import {useTranslation} from "react-i18next";


const Item_details_block_cart = () => {
    const [itemCount, setItemCount] = useState(cart.getItemCount());
    const [subtotal, setSubtotal] = useState(cart.getSubtotal());
    const navigate = useNavigate();
    const appId = useAppId();
    const { t } = useTranslation();


    const iconPath = `/projects/${appId}/images/item_details_block_cart_icon.png`;

    useEffect(() => {
        // Update the cart info whenever the cart changes
        const updateCartInfo = () => {
            setItemCount(cart.getItemCount());
            setSubtotal(cart.getSubtotal());
        };

        cart.subscribe(updateCartInfo);

        return () => {
            cart.unsubscribe(updateCartInfo);
        };
    }, []);

    const handleCartClick = () => {
        navigate('/cart' , { state: { appId } });
    };

    return (
        <div className="item_details_block_cart" onClick={handleCartClick}>
            <img className="item_details_block_cart_icon"  src={iconPath} />
            <div className="item_details_block_cart_details">
                <div className="item_details_block_cart_number">{itemCount}</div>
                <div className="item_details_block_cart_unit item_details_block_cart_unit_text bold">{t("item_details_block_cart_unit_text")}</div>
                <div className="item_details_block_cart_subtotal bold">{subtotal.toFixed(2)}</div>
            </div>
        </div>
    );
};

export default Item_details_block_cart;
