import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppId } from "../AppIdContext";
import { fetchBranches, fetchAddresses } from '../services/api';
import { isLoggedIn, hasSkippedLogin } from '../services/auth';
import {useTranslation} from "react-i18next";


const CartBlockCheckoutButton = () => {
    const [branches, setBranches] = useState([]);
    const [hasAddresses, setHasAddresses] = useState(false);
    const appId = useAppId();
    const navigate = useNavigate();
    const { t } = useTranslation();


    useEffect(() => {
        const loadBranches = async () => {
            try {
                const branchesData = await fetchBranches(appId);
                setBranches(branchesData);
            } catch (error) {
                console.error('Failed to load branches:', error);
            }
        };

        loadBranches();
    }, [appId]);

    useEffect(() => {
        const checkAddresses = async () => {
            try {
                const addresses = await fetchAddresses(appId);
                setHasAddresses(addresses.length > 0);
            } catch (error) {
                console.error('Failed to check addresses:', error);
            }
        };

        if (isLoggedIn()) {
            checkAddresses();
        }
    }, [isLoggedIn()]);

    const handleCheckout = () => {
        if (!isLoggedIn()) {
            navigate('/login');
        } else if (hasAddresses) {
            navigate('/checkout');
        } else {
            navigate('/address/form', { state: { destination: '/checkout'}  });
        }
    };

    if (!branches.length) {
        return <div>Loading...</div>;
    }

    const isCheckoutAvailable = branches[0].status == 1 && branches[0].busy == 0;

    return (
        <div className="cart_block_checkout_holder">
            {isCheckoutAvailable ? (
                <div onClick={handleCheckout} className="cart_block_checkout_button button"> <div className="button_text">{t("cart_block_checkout_button_text")}</div></div>
            ) : (
                <p></p>
            )}
        </div>
    );
};

export default CartBlockCheckoutButton;
