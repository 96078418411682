import React from 'react';
import {useTranslation} from "react-i18next";

const AddressBlockAddSaveButton = ({handleSaveAddress}) => {
    const { t } = useTranslation();


    return (
        <div onClick={handleSaveAddress} className="address_block_save_button bold button">
            <div className="button_text ">{t("address_block_save_button_text")}</div>
        </div>
    );
};

export default AddressBlockAddSaveButton;
