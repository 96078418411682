import React, {useEffect, useState} from 'react';
import {fetchBranches, fetchCategories, fetchItems, fetchOffers} from "../services/api";
import {useAppId} from "../AppIdContext";
import SkeletonList from "../skeletons/SkeletonList";
import SkeletonCategories from "../skeletons/SkeletonCategories";
import {useTranslation} from "react-i18next";

const Product_catalog_block_categories = ({ onSelectCategory, initialCategory }) => {

    const appId = useAppId();
    const [categories, setCategories] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const loadCategories = async () => {
            try {
                setCategories(null);
                const loadedCategories = await fetchCategories(appId);
                setCategories(loadedCategories);
                if (loadedCategories.length > 0) {
                    initialCategory(loadedCategories[0].id)
                }
            } catch (error) {
                console.error('Failed to load items:', error);
            }
        };

        loadCategories();
    }, [appId]);


    if (categories == null ) {
        return (<SkeletonCategories count={6} />); // Show a loading state while configuration or data is being loaded
    }


    if (categories.length == 0) {
        return (
            <div className="no_content">
                {t("product_catalog_layout_no_items_message")}
            </div>
        );
    }


    return (
        <div className="product_catalog_block_categories">
            {categories.map(category => {
                const itemStyle = category.category_image && category.category_image.length > 0 ? {position: 'absolute'} : {position: 'relative'};

                return (
                    <div
                        className="product_catalog_block_categories_item pointer"
                        key={category.id}
                        onClick={() => onSelectCategory(category.id, category.name)}
                    >
                        {category.category_image && category.category_image.length > 0 && (
                            <img
                                className="product_catalog_block_categories_item_cover center_crop"
                                src={category.category_image[0].path}
                                alt={category.name}
                            />
                        )}
                        <div className="product_catalog_block_categories_item_content" style={itemStyle}>
                            {category.category_icon && category.category_icon.length > 0 && (
                                <img
                                    className="product_catalog_block_categories_item_icon center_crop"
                                    src={category.category_icon[0].path}
                                    alt={category.name}
                                />
                            )}
                            <div className="product_catalog_block_categories_item_name bold">
                                {category.name}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Product_catalog_block_categories;
