class Cart {
    constructor() {
        this.cartKey = 'cart_items';
        this.expiryKey = 'cart_expiry';
        this.expiryTime = 6 * 60 * 60 * 1000; // 6 hours
        this.subscribers = [];

        this.loadCart();
    }

    loadCart() {
        const now = new Date().getTime();
        const expiry = localStorage.getItem(this.expiryKey);

        if (expiry && now > expiry) {
            this.clearCart();
        } else {
            this.cartItems = JSON.parse(localStorage.getItem(this.cartKey)) || [];
        }
    }

    saveCart() {
        localStorage.setItem(this.cartKey, JSON.stringify(this.cartItems));
        localStorage.setItem(this.expiryKey, new Date().getTime() + this.expiryTime);
        this.notifySubscribers();
    }

    clearCart() {
        localStorage.removeItem(this.cartKey);
        localStorage.removeItem(this.expiryKey);
        this.cartItems = [];
        this.notifySubscribers();
    }

    addItem(item) {
        const existingItem = this.cartItems.find(cartItem => cartItem.id === item.id);

        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            this.cartItems.push(item);
        }

        this.saveCart();
    }

    removeItem(itemId) {
        this.cartItems = this.cartItems.filter(item => item.id !== itemId);
        this.saveCart();
    }

    updateQuantity(itemId, quantity) {
        const item = this.cartItems.find(cartItem => cartItem.id === itemId);
        if (item) {
            item.quantity = quantity;
            if (item.quantity <= 0) {
                this.removeItem(itemId);
            } else {
                this.saveCart();
            }
        }
    }

    getSubtotal() {
        return this.cartItems.reduce((sum, item) => sum + (item.price ? item.price * item.quantity : 0), 0);
    }

    getItemCount() {
        return this.cartItems.reduce((count, item) => count + item.quantity, 0);
    }

    getItems() {
        return this.cartItems;
    }

    // Method to check if two items are the same (considering variations and add-ons)
    isSameItem(item1, item2) {
        if (item1.id !== item2.id) return false;
        if (JSON.stringify(item1.selectedVariation) !== JSON.stringify(item2.selectedVariation)) return false;
        if (JSON.stringify(item1.selectedAddOns) !== JSON.stringify(item2.selectedAddOns)) return false;
        return true;
    }

    // Method to get item quantity by item ID, variations, and add-ons
    getItemQuantity(item) {
        const existingItem = this.cartItems.find(cartItem => this.isSameItem(cartItem, item));
        return existingItem ? existingItem.quantity : 0;
    }

    // Method to calculate actual user credits
    getActualUserCredits(userCredits) {
        return this.cartItems.reduce((remainingCredits, item) => {
            return remainingCredits - (item.points * item.quantity);
        }, userCredits);
    }

    // Subscription mechanism
    subscribe(callback) {
        this.subscribers.push(callback);
    }

    unsubscribe(callback) {
        this.subscribers = this.subscribers.filter(sub => sub !== callback);
    }

    notifySubscribers() {
        this.subscribers.forEach(callback => callback());
    }
}

export default new Cart();
