import React from 'react';
import {useAppId} from "../AppIdContext";

const Product_catalog_block_logo = () => {

    const appId = useAppId();

    const logoPath = `/projects/${appId}/images/product_catalog_block_logo.png`;



    return (

        <div className="product_catalog_block_logo" >
            <img src={logoPath} alt="Logo" className="center_crop" />
        </div>
    );
};

export default Product_catalog_block_logo;
