import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useAppId} from "../AppIdContext";


const BackButton = ( ) => {

    const appId = useAppId();

    const logoPath = `/projects/${appId}/images/back_icon.png`;

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // Navigate back to the previous screen
    };

    return (
        <div  onClick={handleBackClick} className="item_details_block_back item_details_block_back_icon pointer" src={logoPath} alt="Back" > ← </div>
    );

};

export default BackButton;

