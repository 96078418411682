import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ComponentRenderer from "../utils/ComponentRenderer";
import { useAppId } from "../AppIdContext";

const ProductCatalogOverlay = () => {
    const location = useLocation();
    const { selectedCategoryId, selectedCategoryName, config, userCredits, mode } = location.state || {};
    const [productCatalogPlacements, setPlacements] = useState(null);
    const [currentCategoryId, setCurrentCategoryId] = useState(config.product_catalog_block_subcategories_enable ? null : selectedCategoryId);
    const appId = useAppId();

    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/product_catalog_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);

    const handleSubCategorySelect = (subCategoryId) => {
        setCurrentCategoryId(subCategoryId);
    };

    if (!productCatalogPlacements) {
        return <div></div>;
    }

    const data = {
        block_back: {
            appId: appId,
        },
        product_catalog_block_items: {
            config,
            selectedCategoryId: currentCategoryId,
        },
        product_catalog_block_overlay_title: {
            selectedCategoryName,
        },
        product_catalog_block_loyalty_items: {
            userCredits,
        },
        product_catalog_block_subcategories: {
            categoryId: selectedCategoryId,
            onSubCategorySelect: handleSubCategorySelect,
            config: config,
        },
    };

    const renderer = new ComponentRenderer(productCatalogPlacements, data);

    return (
        <div className="product_catalog_layout_overlay ">
            <div className="product_catalog_layout_overlay_toolbar toolbar">
                <div className="product_catalog_layout_overlay_toolbar_position_start toolbar_start">{renderer.renderComponents('product_catalog_layout_overlay_toolbar_position_start')}</div>
                <div className="product_catalog_layout_overlay_toolbar_position_center toolbar_center">{renderer.renderComponents('product_catalog_layout_overlay_toolbar_position_center')}</div>
                <div className="product_catalog_layout_overlay_toolbar_position_end toolbar_end">{renderer.renderComponents('product_catalog_layout_overlay_toolbar_position_end')}</div>
            </div>
            <div className="product_catalog_layout_overlay_position_content_top">{renderer.renderComponents(`product_catalog_layout_overlay_position_content_top_${mode}`)}</div>
            <div className="product_catalog_layout_overlay_position_content">{renderer.renderComponents(`product_catalog_layout_overlay_position_content_${mode}`)}</div>
            {config.product_catalog_layout_footer_enable && (
                <div className="product_catalog_layout_footer_holder">
                    <div className="product_catalog_layout_footer">
                        <div className="product_catalog_layout_footer_positions">
                            <div className="product_catalog_layout_overlay_footer_position_footer_start">{renderer.renderComponents('product_catalog_layout_overlay_footer_position_footer_start')}</div>
                            <div className="product_catalog_layout_overlay_footer_position_footer_center flex_1">{renderer.renderComponents('product_catalog_layout_overlay_footer_position_footer_center')}</div>
                            <div className="product_catalog_layout_overlay_footer_position_footer_end">{renderer.renderComponents('product_catalog_layout_overlay_footer_position_footer_end')}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductCatalogOverlay;
