import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { fetchBranches } from '../services/api';
import { useAppId } from "../AppIdContext";




const AddressBlockForm = ({ config , onFormChange }) => {
    const location = useLocation();
    const address = location.state?.address || null;
    const { t } = useTranslation();
    const appId = useAppId();
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setselectedProvince] = useState('');
    const [cities, setCities] = useState([]);
    const [selectedCity, setselectedCity] = useState('');
    const [areas, setAreas] = useState([]);
    const [selectedArea, setselectedArea] = useState('');
    const [formValues, setFormValues] = useState({
        default: false,
        full_name: '',
        phone: '',
        mail: '',
        province: '',
        city: '',
        area: '',
        flat: '',
        building: '',
        street: '',
        postal_code: '',
    });

    useEffect(() => {
        if (address) {
            setFormValues({
                id: address.id || '',
                default: address.default || false,
                full_name: address.full_name || '',
                phone: address.phone || '',
                mail: address.mail || '',
                province: address.province || '',
                city: address.city || '',
                area: address.area || '',
                flat: address.flat || '',
                building: address.building || '',
                street: address.street || '',
                postal_code: address.postal_code || '',
            });

            setselectedProvince(address.province);
            setselectedCity(address.city);
            setselectedArea(address.area);
        }
    }, [address]);

    useEffect(() => {
        onFormChange(formValues);
    }, [formValues]);

    useEffect(() => {
        const loadProvinces = async () => {
            try {
                const response = await fetchBranches(appId);
                const coverage = response?.[0]?.coverage || [];
                setProvinces(coverage);

                if (coverage.length === 1) {
                    setselectedProvince(coverage[0].name);
                    setFormValues((prev) => ({ ...prev, province: coverage[0].name }));
                }
            } catch (error) {
                console.error('Failed to load provinces:', error);
            }
        };

        loadProvinces();
    }, [appId]);



    useEffect(() => {
        if (selectedProvince) {
            const provinceData = provinces.find(province => province.name === selectedProvince);
            setCities(provinceData?.cities || []);
            setselectedArea(null);
            setselectedCity(null);
        }
    }, [selectedProvince, provinces]);



    useEffect(() => {
        if (selectedCity) {
            const cityData = cities.find(city => city.name === selectedCity);
            const uniqueAreas = (cityData?.areas || []).filter((area, index, self) =>
                index === self.findIndex((a) => (
                    a.name === area.name
                ))
            );
            setAreas(uniqueAreas);
        }
    }, [selectedCity, cities]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prev => ({ ...prev, [name]: value }));

    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormValues(prev => ({ ...prev, [name]: checked }));
    };


    return (
        <form className="address_block_form">
            <div className="flex address_block_form_default">
                <label className="switch">
                    <input type="checkbox"
                           name="default"
                           checked={formValues.default}
                           onChange={handleCheckboxChange} />
                           <span className="slider round"></span>

                </label>
                <span className="flex_1 p10"  > {t('address_block_form_default_text')} </span>

            </div>
            <div>

                <input
                    className="address_block_form_input address_block_form_full_name"
                    type="text"
                    name="full_name"
                    value={formValues.full_name}
                    onChange={handleInputChange}
                    variant="outlined"
                    placeholder={t('address_block_form_input_full_name_hint')}
                />
            </div>
            <div>
                <input
                    className="address_block_form_input address_block_form_phone"
                    type="tel"
                    name="phone"
                    value={formValues.phone}
                    onChange={handleInputChange}
                    placeholder={t('address_block_form_input_phone_hint')}
                />
            </div>
            <div>
                <input
                    className="address_block_form_input address_block_form_mail"
                    type="email"
                    name="mail"
                    value={formValues.mail}
                    onChange={handleInputChange}
                    placeholder={t('address_block_form_input_mail_hint')}
                />
            </div>
            <div>
                {provinces.length > 1 ? (
                    <select
                        className="address_block_form_input address_block_form_province"
                        name="province"
                        value={formValues.province}
                        onChange={(e) => {
                            setselectedProvince(e.target.value);
                            handleInputChange(e);
                        }}
                    >
                        <option>{t('address_block_form_provinces_hint')}</option>
                        {provinces.map(province => (
                            <option
                                key={province.name}
                                value={province.name}
                                //selected={province.name === formValues.province}
                            >{province.name}</option>
                        ))}
                    </select>

                ) : (
                    <input
                        className="address_block_form_input"
                        type="text"
                        name="province"
                        value={formValues.province}
                        onChange={handleInputChange}
                        placeholder={t('address_block_form_provinces_hint')}
                        readOnly
                    />
                )}
            </div>
            <div className="address_block_form_city_area flex">
                {selectedProvince || formValues.city ? (
                    <div className="flex_1 address_block_form_city">
                        {cities.length > 0 ? (
                            <select
                                className="address_block_form_input"
                                name="city"
                                value={formValues.city}
                                placeholder={t('address_block_form_input_city_hint')}
                                onChange={(e) => {
                                    setselectedCity(e.target.value);
                                    handleInputChange(e);
                                }}
                            >
                                <option>{t('address_block_form_input_city_hint')}</option>
                                {cities.map(city => (
                                    <option key={city.name} value={city.name}>{city.name}</option>
                                ))}
                            </select>
                        ) : (
                            <input
                                className="address_block_form_input"
                                type="text"
                                name="city"
                                value={formValues.city}
                                onChange={handleInputChange}
                                placeholder={t('address_block_form_input_city_hint')}
                            />
                        )}
                    </div>
                ) : null }
                {selectedCity || formValues.area ? (
                    <div className="flex_1 address_block_form_area">
                        {areas.length > 0 ? (
                            <select
                                className="address_block_form_input"
                                name="area"
                                placeholder={t('address_block_form_input_area_hint')}
                                value={formValues.area}
                                onChange={(e) => {
                                    setselectedArea(e.target.value);
                                    handleInputChange(e);
                                }}
                            >
                                <option>{t('address_block_form_input_area_hint')}</option>
                                {areas.map(area => (
                                    <option key={area.name} value={area.name}>{area.name}</option>
                                ))}
                            </select>
                        ) : (
                            <input
                                className="address_block_form_input"
                                type="text"
                                name="area"
                                value={formValues.area}
                                onChange={handleInputChange}
                                placeholder={t('address_block_form_input_area_hint')}
                            />
                        )}
                    </div>
                ) : null }
            </div>

            <div className="address_block_form_flat_building flex">
                <div className="flex_1">
                    <input
                        className="address_block_form_input address_block_form_flat"
                        type="text"
                        name="flat"
                        value={formValues.flat}
                        onChange={handleInputChange}
                        placeholder={t('address_block_form_input_flat_hint')}
                    />
                </div>
                <div className="flex_1">
                    <input
                        className="address_block_form_input address_block_form_building"
                        type="text"
                        name="building"
                        value={formValues.building}
                        onChange={handleInputChange}
                        placeholder={t('address_block_form_input_building_hint')}
                    />
                </div>
            </div>

            <div>
                <textarea
                    className="address_block_form_input address_block_form_street"
                    name="street"
                    value={formValues.street}
                    onChange={handleInputChange}
                    placeholder={t('address_block_form_input_street_hint')}
                    rows="3"
                />
            </div>
            <div>
                <input
                    className="address_block_form_input address_block_form_postal_code"
                    type="text"
                    name="postalCode"
                    value={formValues.postalCode}
                    onChange={handleInputChange}
                    placeholder={t('address_block_form_input_postal_code_hint')}
                />
            </div>
        </form>
    );
};

export default AddressBlockForm;
