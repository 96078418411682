import React from 'react';
import {useAppId} from "../AppIdContext";

const ItemDetailsBlockQuantity = ({ quantity, onIncrease, onDecrease }) => {
    const appId = useAppId();
    const plus = `/projects/${appId}/images/plus_up.png`;
    const minus = `/projects/${appId}/images/minus_icon.png`;
    return(
        <div className="item_details_block_quantity">
            <span className="item_details_block_quantity_down button" onClick={onDecrease} > <div className="item_details_block_quantity_down_icon" >-</div></span>
            <span className="item_details_block_quantity_text">{quantity}</span>
            <span className="item_details_block_quantity_up button" onClick={onIncrease} > <div className="item_details_block_quantity_up_icon"  >+</div></span>
        </div>
    );
};

export default ItemDetailsBlockQuantity;
