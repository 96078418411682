import React from 'react';

const Splash_block_copyrights = ({ appId }) => {
    const style = {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
    };

    return (
        <div className="splash_block_copyrights" style={style}>
            <p>&copy; 2024 Your Company</p>
        </div>
    );
};

export default Splash_block_copyrights;
