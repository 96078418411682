import React from 'react';
import { useTranslation } from 'react-i18next';
import {useAppId} from "../AppIdContext";

const ItemDetailsBlockAddToCart = ({ onAddToCart }) => {

    const appId = useAppId();

    const { t } = useTranslation();

    const iconPath = `/projects/${appId}/images/item_details_block_add_to_cart_icon.png`;

    return (
        <div className="item_details_block_add_to_cart button flex" onClick={onAddToCart}>
            <img className="item_details_block_add_to_cart_icon button_icon" src={iconPath} />
            <div className="item_details_block_add_to_cart_text button_text bold">{t("item_details_block_add_to_cart_text")}</div>
        </div>
    );
};

export default ItemDetailsBlockAddToCart;
