import React from 'react';
import {useTranslation} from "react-i18next";

const OrdersBlockAddress = ({ address }) => {

    const {t} = useTranslation();
    return (
        <div className="orders_block_address mode-invoice">
            <div className="orders_block_address_title orders_block_address_title_text bold">{t("orders_block_address_title_text")}</div>
            <div className="orders_block_address_line">{address}</div>
        </div>
    );
};

export default OrdersBlockAddress;
