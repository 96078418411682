// src/components/login_block_forgot_password.js
import React from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const LoginBlockForgotPassword = () => {

    const {t} = useTranslation();

    return (
        <div className="login_block_forgot_password">
            <Link to="/forgot-password">{t("login_block_forgot_password_text")}</Link>
        </div>
    );
};



export default LoginBlockForgotPassword;
