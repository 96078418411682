import React from 'react';
import {useAppId} from "../AppIdContext";

const Splash_block_background = () => {

    const appId = useAppId();

    const backgroundPath = `/projects/${appId}/images/splash_block_background.jpg`;

    const style = {
        background: `url(${backgroundPath}) no-repeat center center `,
        backgroundSize: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
    };

    return <div style={style} ></div>;
};

export default Splash_block_background;
