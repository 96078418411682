import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ComponentRenderer from "../utils/ComponentRenderer";
import { useAppId } from "../AppIdContext";

const CheckoutConfirmation = ({ config }) => {
    const [checkoutPlacements, setPlacements] = useState(null);
    const appId = useAppId();
    const location = useLocation();
    const orderNumber = location.state?.orderNumber || null;

    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/checkout_confirmation_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };
        loadPlacements();
    }, [appId]);

    const navigate = useNavigate();

    useEffect(() => {
        const handleBackNavigation = (event) => {
            event.preventDefault();
            navigate('/catalog', { replace: true });
        };

        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [navigate]);

    const data = {
        checkout_block_confirmation: {
            orderNumber: orderNumber,
        },
    };

    if (!checkoutPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(checkoutPlacements, data);

    return (
        <div className="checkout_layout">
            <div className="checkout_layout_content_position_content checkout_layout_content">
                {renderer.renderComponents('checkout_layout_content_position_content')}
            </div>
        </div>
    );
};

export default CheckoutConfirmation;
