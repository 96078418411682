import React from 'react';
import {useTranslation} from "react-i18next";

const CheckoutBlockNote = ({ note, onNoteChange }) => {
    const {t} = useTranslation();
    return (
        <div className="checkout_block_note">
            <div className="checkout_block_note_title checkout_block_note_title_text bold">{t("checkout_block_note_title_text")}</div>
            <textarea
                className="checkout_block_note_input"
                value={note}
                onChange={e => onNoteChange(e.target.value)}
                placeholder={t("checkout_block_note_input_hint")}
            />
        </div>
    );
};

export default CheckoutBlockNote;
