import React, { useEffect, useState } from 'react';
import { fetchDefaultAddresses } from '../services/api';
import { useNavigate } from 'react-router-dom';
import {useAppId} from "../AppIdContext";
import {useTranslation} from "react-i18next";

const CheckoutBlockAddress = ({ address, onAddressChange }) => {
    const navigate = useNavigate();
    const appId = useAppId();
    const {t} = useTranslation();


    /*useEffect(() => {
        fetchDefaultAddresses(appId).then(data => {
            if (data.length === 0) {
                navigate('/address/form');
            } else {
                onAddressChange(data[0]);
            }
        }).catch(error => {
            console.error('Failed to fetch default address:', error);
        });
    }, [appId, navigate, onAddressChange]);*/

    if (!address) return null;

    return (
        <div className="checkout_block_address mode-checkout">
            <div className="checkout_block_address_title checkout_block_address_title_text bold">{t("checkout_block_address_title_text")}</div>
            <div>
                <div className="checkout_block_address_default_address">
                    {`${address.full_name}, ${address.flat} ${address.building}, ${address.street}, ${address.area}, ${address.city}, ${address.province}, ${address.postal_code}, ${address.country}`}
                </div>
            </div>
            <div className="checkout_block_address_manage_addresses bold button" onClick={() => navigate('/address')}>{t("checkout_block_address_manage_addresses_text")}</div>
        </div>
    );
};

export default CheckoutBlockAddress;
