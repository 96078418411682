import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { loadLocales } from './utils/localeLoader';

const initializeI18n = async (appId) => {
    try {
        const { en, ar } = await loadLocales(appId);

        i18n.use(initReactI18next).init({
            resources: {
                en: {
                    translation: en.default,
                },
                ar: {
                    translation: ar.default,
                },
            },
            lng: 'en', // Default language
            fallbackLng: 'en', // Fallback language
            //debug: true,
            interpolation: {
                escapeValue: false, // React already safes from XSS
            },
        });
    } catch (error) {
        console.error('Failed to initialize i18n:', error);
    }
};

export default initializeI18n;
