// src/components/login_block_terms.js
import React from 'react';
import {useTranslation} from "react-i18next";

const LoginBlockTerms = ({ termsLink }) => {
    const {t} = useTranslation();

    return (
        <div className="login_block_terms">
            <a href={termsLink} target="_blank" rel="noopener noreferrer">{t("login_block_terms_text")}</a>
        </div>
    );
};

export default LoginBlockTerms;
