import React, { useState, useEffect } from 'react';
import { validateCoupon, fetchAutoCoupons } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const CheckoutBlockCoupon = ({ onCouponApplied }) => {
    const [couponCode, setCouponCode] = useState('');
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [error, setError] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        const autoApplyCoupon = async () => {
            try {
                const autoCouponData = await fetchAutoCoupons();
                if (autoCouponData && autoCouponData.available_coupons && autoCouponData.available_coupons.length > 0) {
                    for (let coupon of autoCouponData.available_coupons) {
                        const include = coupon.combined !== 0 || autoCouponData.available_coupons.length <= 1;
                        if (include && ['percentage', 'fixed', 'free_service'].includes(coupon.type)) {
                            const couponValidation = await validateCoupon(coupon.code);
                            if (!couponValidation.error_code) {
                                if (onCouponApplied(couponValidation)) {
                                    setAppliedCoupon(couponValidation.code);
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Auto coupon validation error:', error);
            }
        };

        autoApplyCoupon();
    }, []);

    const handleApplyCoupon = async () => {
        try {
            const response = await validateCoupon(couponCode);
            if (response.error_code) {
                toast.error(`Error: ${response.status}`);
                setError(response.status);
            } else {

                if (onCouponApplied(response)) {
                    setAppliedCoupon(response.code);
                } else {
                    toast.error('Invalid Coupon.');
                }
            }
        } catch (error) {
            toast.error('Failed to validate coupon.');
            console.error('Failed to validate coupon:', error);
        }
    };

    return (
        <div className="checkout_block_coupon mode-checkout">
            <div className="checkout_block_coupon_title checkout_block_coupon_title_text bold">{t("checkout_block_coupon_title_text")}</div>
            {appliedCoupon ? (
                <div className="checkout_block_coupon_applied_code">{appliedCoupon}</div>
            ) : (
                <>
                    {error && <div className="checkout_block_coupon_error">{error}</div>}
                    <div className="checkout_block_coupon_holder">
                        <input
                            className="checkout_block_coupon_code checkout_block_coupon_code_hint input_text"
                            value={couponCode}
                            placeholder={t("checkout_block_coupon_code_hint")}
                            onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <div className="checkout_block_coupon_button button" onClick={handleApplyCoupon}>
                            <div className="checkout_block_coupon_button_text button_text bold">{t("checkout_block_coupon_button_text")}</div>
                        </div>
                    </div>
                </>
            )}
            <ToastContainer />
        </div>
    );
};

export default CheckoutBlockCoupon;
