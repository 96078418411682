import React, { useState, useEffect } from 'react';
import cart from '../services/cart';
import { useNavigate } from 'react-router-dom';
import {useAppId} from "../AppIdContext";
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from "react-toastify";


const Product_catalog_block_cart = () => {
    const [itemCount, setItemCount] = useState(cart.getItemCount());
    const [subtotal, setSubtotal] = useState(cart.getSubtotal());
    const navigate = useNavigate();
    const appId = useAppId();
    const { t } = useTranslation();


    const iconPath = `/projects/${appId}/images/item_details_block_cart_icon.png`;


    const notify_error = (message) => toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {
        // Update the cart info whenever the cart changes
        const updateCartInfo = () => {
            setItemCount(cart.getItemCount());
            setSubtotal(cart.getSubtotal());
        };

        cart.subscribe(updateCartInfo);

        return () => {
            cart.unsubscribe(updateCartInfo);
        };
    }, []);

    const handleCartClick = () => {
        if (itemCount > 0) {
            navigate('/cart' , { state: { appId } });
        } else {
            notify_error("Cart is empty.");
        }
    };

    return (
        <div className="product_catalog_block_cart pointer" onClick={handleCartClick}>
            <img className="product_catalog_block_cart_icon"  src={iconPath} />
            <div className="product_catalog_block_cart_details">
                <div className="product_catalog_block_cart_number">{itemCount}</div>
                <div className="product_catalog_block_cart_unit product_catalog_block_cart_unit_text bold">{t("product_catalog_block_cart_unit_text")}</div>
                <div className="product_catalog_block_cart_subtotal bold">{subtotal.toFixed(2)}</div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Product_catalog_block_cart;
