export const loadLocales = async (appId) => {
    try {
        const en = await import(`../projects/${appId}/locales/en.json`);
        const ar = await import(`../projects/${appId}/locales/ar.json`);
        return { en, ar };
    } catch (error) {
        console.error('Error loading locales:', error);
        throw new Error('Locale files not found');
    }
};
