import React, { useState, useEffect, useRef } from 'react';
import { fetchCategories } from '../services/api';
import { useAppId } from "../AppIdContext";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

const ProductCatalogBlockTabs = ({ onCategorySelect, initialCategory, config }) => {

    const [Categories, setCategories] = useState([]);
    const categoriesRef = useRef([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const appId = useAppId();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const loadCategories = async () => {
            try {
                const CategoriesData = await fetchCategories(appId);
                setCategories(CategoriesData);
                categoriesRef.current = CategoriesData; // Update the ref
                console.log(CategoriesData[0].id);
                onCategorySelect(CategoriesData[0].id);
                initialCategory(CategoriesData[0].id)
            } catch (error) {
                console.error('Failed to load categories:', error);
            }
        };

        loadCategories();
    }, [appId]);

    const handleCategoryClick = (index) => {
        const selectedCategory = categoriesRef.current[index];
        if (selectedCategory) {
            setSelectedCategoryId(selectedCategory.id);
            onCategorySelect(selectedCategory.id);
        }
    };

    const onTabClick = (e, index) => {
        console.log('Tab clicked:', index);
        if (categoriesRef.current.length > 0) {
            setActiveTab(index);
            handleCategoryClick(index);
        } else {
            console.warn('Attempted to click tab before categories loaded');
        }
    };

    return (
        <div className="product_catalog_layout_tabs">
            <Tabs isRTL={config.global_enable_rtl} hideNavBtns={true} activeTab={activeTab} onTabClick={onTabClick}>
                {Categories.map((Category, index) => (
                    <Tab className="product_catalog_layout_tabs_item" key={Category.id}>{Category.name}</Tab>
                ))}
            </Tabs>
        </div>
    );
};

export default ProductCatalogBlockTabs;


