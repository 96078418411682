// src/components/LoginBlockGoogle.js
import React, { useEffect } from 'react';
import { useAppId } from "../AppIdContext";
import { useTranslation } from "react-i18next";
import { registerUser } from '../services/api'; // Import the registerUser function
import { handleLoginSuccess } from '../services/auth'; // Import the handleLoginSuccess function
import { jwtDecode } from 'jwt-decode'


const LoginBlockGoogle = ({ config }) => {
    const appId = useAppId();
    const iconPath = `/projects/${appId}/images/login_block_social_google_icon.png`;
    const { t } = useTranslation();

    const client_id = t("login_block_social_google_server_client_id");

    useEffect(() => {
        const loadGoogleSDK = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.onload = initializeGoogleLogin;
            document.body.appendChild(script);
        };

        const initializeGoogleLogin = () => {
            window.google.accounts.id.initialize({
                client_id: client_id,
                callback: handleGoogleLogin
            });
        };

        const handleGoogleLogin = async (response) => {
            const user = jwtDecode(response.credential);
            const { name, email, picture, sub: googleId } = user;
            console.log(user);
            console.log(name);
            try {
                const registerResponse = await registerUser(name, email, '', appId, email, 'google', 'web');
                const { token } = registerResponse;
                handleLoginSuccess(token, name, picture);
            } catch (error) {
                console.error('Failed to register user:', error);
            }
        };

        loadGoogleSDK();
    }, [client_id, appId]);

    const handleLogin = () => {
        window.google.accounts.id.prompt();
    };

    return (
        <div className="login_block_google login_block_social_google_button login_block_social_button button" onClick={handleLogin}>
            <img className="login_block_social_google_icon login_block_social_button_icon button_icon button_icon" src={iconPath} />
            <div className="login_block_social_google_button_text button_text button_text bold">{t("login_block_social_google_button_text")}</div>
        </div>
    );
};

export default LoginBlockGoogle;
