// SplashScreen.js
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ComponentRenderer from "../utils/ComponentRenderer";
import { isLoggedIn, hasSkippedLogin } from '../services/auth';
import { fetchCategories, fetchOffers, fetchBranches } from '../services/api';
import { useAppId } from '../AppIdContext';

const SplashScreen = ({ onComplete, config }) => {
    const appId = useAppId(); // Use the appId from context
    const [splashPlacements, setSplashPlacements] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initialize = async () => {
            if (isLoggedIn() || hasSkippedLogin()) {
                try {
                    const [categories, offers, branches] = await Promise.all([
                        fetchCategories(appId),
                        fetchOffers(appId),
                        fetchBranches(appId),
                    ]);
                    setTimeout(() => {
                        onComplete('catalog', categories, offers, branches);
                    }, config.splash_timeout); // Wait for 5 seconds before proceeding to the next screen
                } catch (error) {
                    console.error('Failed to load initial data:', error);
                }
            } else {
                // Redirect to login or show login screen after 5 seconds
                setTimeout(() => {
                    onComplete('login');
                }, config.splash_timeout); // 5-second delay
            }
        };

        const loadSplashPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/splash_placements.json`);
                setSplashPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load splash placements:', error);
            }
        };

        loadSplashPlacements();
        initialize();
    }, [onComplete, config.splash_timeout, appId]);

    useEffect(() => {
        if (splashPlacements) {
            setLoading(false);
        }
    }, [splashPlacements]);

    const renderLoadingSkeletons = () => (
        <div className="splash_layout">
            <Skeleton height={"100%"} />
        </div>
    );

    if (loading) {
        return renderLoadingSkeletons();
    }

    const data = {};

    const renderer = new ComponentRenderer(splashPlacements, data);

    return (
        <div className="splash_layout">
            {renderer.renderComponents('splash_layout_holder_position_content_position_background')}
            {renderer.renderComponents('splash_layout_holder_position_content_position_center')}
            {renderer.renderComponents('splash_layout_holder_position_content_position_bottom')}
        </div>
    );
};

export default SplashScreen;
