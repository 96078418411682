// services/auth.js
import Cookies from 'js-cookie';

export const isLoggedIn = () => {
    // Check if the token cookie is set
    return Cookies.get('mobstep_customer_token') !== undefined;
};

export const handleLoginSuccess = (token, name, image) => {
    Cookies.set('mobstep_customer_token', token.trim(), { expires: 30, secure: true, sameSite: 'Strict' });
    localStorage.setItem('user_name', name);
    localStorage.setItem('user_image', image);
    window.location.href = '/catalog'; // Redirect to catalog on successful login
};

export const handleRegisterSuccess = (token, name) => {
    Cookies.set('mobstep_customer_token', token.trim(), { expires: 30, secure: true, sameSite: 'Strict' });
    localStorage.setItem('user_name', name);
    window.location.href = '/catalog'; // Redirect to catalog on successful registration
};

export const skipLogin = () => {
    localStorage.setItem('skipped', 'true');
    window.location.href = '/catalog';
};

export const hasSkippedLogin = () => {
    return localStorage.getItem('skipped') === 'true';
};

export const getToken = () => {
    return Cookies.get('mobstep_customer_token');
};

export const handleLogout = () => {
    Cookies.remove('mobstep_customer_token');
    localStorage.clear();
    window.location.href = '/splash';
};

export const getUserName = () => {
    return localStorage.getItem('user_name');
};

export const getUserImage = () => {
    return localStorage.getItem('user_image');
};
