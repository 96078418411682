import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './global_styles.css';
import { AppIdProvider } from './AppIdContext';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Router>
            <AppIdProvider>
                <App />
            </AppIdProvider>
        </Router>
    </React.StrictMode>
);
