import React from 'react';
import { useTranslation } from 'react-i18next';




const Splash_block_slogan = ({ appId }) => {

    const { t } = useTranslation();

    const style = {
        position: 'absolute',
        top: '60%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    };


    return (
        <div className="splash_block_slogan" style={style}>
            <p>{t("splash_block_slogan_text")}</p>
    </div>
);
};

export default Splash_block_slogan;
