import React, { useState, useEffect, useRef } from 'react';
import { fetchSubCategories } from '../services/api';
import { useAppId } from "../AppIdContext";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

const ProductCatalogBlockSubcategories = ({ categoryId, onSubCategorySelect, config }) => {

    const [subCategories, setSubCategories] = useState([]);
    const subCategoriesRef = useRef([]);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
    const appId = useAppId();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const loadSubCategories = async () => {
            try {
                const subCategoriesData = await fetchSubCategories(appId, categoryId);
                setSubCategories(subCategoriesData);
                subCategoriesRef.current = subCategoriesData; // Update the ref
                console.log(subCategoriesData[0].id);
                onSubCategorySelect(subCategoriesData[0].id);
            } catch (error) {
                console.error('Failed to load subcategories:', error);
            }
        };

        loadSubCategories();
    }, [appId, categoryId]);

    const handleSubCategoryClick = (index) => {
        const selectedSubCategory = subCategoriesRef.current[index];
        if (selectedSubCategory) {
            setSelectedSubCategoryId(selectedSubCategory.id);
            onSubCategorySelect(selectedSubCategory.id);
        }
    };

    const onTabClick = (e, index) => {
        console.log('Tab clicked:', index);
        if (subCategoriesRef.current.length > 0) {
            setActiveTab(index);
            handleSubCategoryClick(index);
        } else {
            console.warn('Attempted to click tab before subcategories loaded');
        }
    };

    return (
        <div className="product_catalog_block_subcategories">
            <Tabs isRTL={config.global_enable_rtl} hideNavBtns={true} activeTab={activeTab} onTabClick={onTabClick}>
                {subCategories.map((subCategory, index) => (
                    <Tab key={subCategory.id}>{subCategory.name}</Tab>
                ))}
            </Tabs>
        </div>
    );
};

export default ProductCatalogBlockSubcategories;
