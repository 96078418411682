import React from 'react';
import SkeletonCategoryItem from './SkeletonCategoryItem';

const SkeletonCategories = ({ count }) => {
    return (
        <div className="product_catalog_block_categories">
            {Array(count)
                .fill()
                .map((_, index) => (
                    <SkeletonCategoryItem key={index} />
                ))}
        </div>
    );
};

export default SkeletonCategories;
