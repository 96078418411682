// src/components/login_block_text.js
import React from 'react';
import {useTranslation} from "react-i18next";

const LoginBlockTitle = () => {
    const {t} = useTranslation();
    return (
        <div className="login_block_title">
            <p className="login_block_title_text bold">{t("login_block_title_text")}</p>
        </div>
    );
};

export default LoginBlockTitle;
