import React, { useState, useEffect } from 'react';
import ComponentRenderer from "../utils/ComponentRenderer";
import {useAppId} from "../AppIdContext";
import { useLocation } from 'react-router-dom';

const OrderDetails = () => {
    const [ordersDetailsPlacements, setPlacements] = useState(null);
    const appId = useAppId();

    const location = useLocation();
    const order = location.state?.order || {};


    useEffect(() => {
        const loadPlacements = async () => {
            try {
                const placements = await import(`../projects/${appId}/placements/orders_details_placements.json`);
                setPlacements(placements.default);
            } catch (error) {
                console.error('Failed to load placements:', error);
            }
        };

        loadPlacements();
    }, [appId]);


    const data = {
        block_back: {
            appId: appId,
        },
        orders_block_header: {
            order: order,
        },
        orders_block_address: {
            address: order.address,
        },
        orders_block_items: {
            items: order.field_order_item,
        },
        orders_block_summary: {
            order: order,
        },
    };

    if (!ordersDetailsPlacements) {
        return <div></div>; // Show a loading state while configuration or data is being loaded
    }

    const renderer = new ComponentRenderer(ordersDetailsPlacements, data);

    return (
        <div className="orders_layout">
            <div className="orders_layout_toolbar toolbar">
                <div className="orders_layout_header_position_header_start toolbar_start">{renderer.renderComponents('orders_layout_header_position_header_start')}</div>
                <div className="orders_layout_header_position_header_center toolbar_center">{renderer.renderComponents('orders_layout_header_position_header_center')}</div>
                <div className="orders_layout_header_position_header_end toolbar_end">{renderer.renderComponents('orders_layout_header_position_header_end')}</div>
            </div>
            <div className="orders_layout_content_position_content orders_layout_content">{renderer.renderComponents('orders_layout_content_position_content')}</div>
            <div className="orders_layout_footer_holder">
                <div className="orders_layout_footer">
                    <div className="orders_layout_footer_positions">
                        <div className="orders_layout_footer_position_footer_start">{renderer.renderComponents('orders_layout_footer_position_footer_start')}</div>
                        <div className="orders_layout_footer_position_footer_center">{renderer.renderComponents('orders_layout_footer_position_footer_center')}</div>
                        <div className="orders_layout_footer_position_footer_end">{renderer.renderComponents('orders_layout_footer_position_footer_end')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetails;
