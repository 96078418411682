import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const AddressBlockAddNewAddressButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleAddNewAddress = () => {
        navigate('/address/form');
    };

    return (
        <div onClick={handleAddNewAddress} className="address_block_add_new_address_button bold button">
            <div className="button_text ">{t("address_block_add_new_address_button_text")}</div>
        </div>
    );
};

export default AddressBlockAddNewAddressButton;
