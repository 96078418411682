import React from 'react';
import {useAppId} from "../AppIdContext";

const LoginBlockLogo = () => {

    const appId = useAppId();
    const logoPath = `/projects/${appId}/images/login_block_logo.png`;
    return (
        <div className="login_block_logo">
            <img src={logoPath} alt="Logo"/>
        </div>
    );
};

export default LoginBlockLogo;
