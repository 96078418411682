import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchOrders } from '../services/api';
import { useTranslation } from "react-i18next";
import { useAppId } from "../AppIdContext";
import { format } from 'date-fns';
import Skeleton from "react-loading-skeleton";

const OrdersBlockOrders = () => {
    const [orders, setOrders] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appId = useAppId();

    useEffect(() => {
        const loadOrders = async () => {
            try {
                const ordersData = await fetchOrders(appId);
                setOrders(ordersData);
            } catch (error) {
                console.error('Failed to load orders:', error);
            }
        };
        loadOrders();
    }, [appId]);

    const handleOrdersClick = (order) => {
        navigate('/order/details', { state: { order: order } });
    };

    const formatDate = (timestamp) => {
        return format(new Date(timestamp * 1000), 'd/MM/yyyy HH:mm');
    };

    const renderLoadingSkeletons = () => (
        <div className="orders_block_orders">
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
            <Skeleton height={200} />
        </div>
    );

    if (!orders) {
        return renderLoadingSkeletons(); // Show a loading state while configuration or data is being loaded
    }

    if (orders.length == 0) {
        return (
            <div className="no_content orders_block_orders_no_orders">
                {t("orders_block_orders_no_orders_message")}
            </div>
        );
    }

    return (
        <div className="orders_block_orders">
            {orders.map((order) => (
                <div
                    key={order.id}
                    className="orders_block_orders_item"
                    onClick={() => handleOrdersClick(order)}
                >
                    <div className="orders_block_orders_item_holder">
                        <div className="orders_block_orders_item_number orders_block_orders_item_value bold">
                            #{order.id}
                        </div>
                        <div className="orders_block_orders_item_date orders_block_orders_item_value">
                            {formatDate(order.created)}
                        </div>
                        <div className="orders_block_orders_item_status orders_block_orders_item_value bold">
                            {order.status}
                        </div>
                        <div className="orders_block_orders_item_total orders_block_orders_item_value bold">
                            {order.total}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrdersBlockOrders;
