import React from 'react';
import {useAppId} from "../AppIdContext";

const Product_catalog_block_menu_icon = ({ onClick }) => {

    const appId = useAppId();
    const iconPath = `/projects/${appId}/images/product_catalog_block_menu_icon.png`;

    return (

        <div onClick={onClick} className="product_catalog_block_menu_icon button" >
            <img src={iconPath} alt="Open Menu"  />
        </div>
    );
};

export default Product_catalog_block_menu_icon;
