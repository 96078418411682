import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, useMapEvents, TileLayer, useMap, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from "react-i18next";
import { useAppId } from "../AppIdContext";
import L from 'leaflet';


// Custom marker icon
const createMarkerIcon = (iconUrl) => {
    return new L.Icon({
        iconUrl,
        iconSize: [50, 50], // Size of the icon
        iconAnchor: [25, 50], // Point of the icon which will correspond to marker's location
        popupAnchor: [0, -50] // Point from which the popup should open relative to the iconAnchor
    });
};

const AddressBlockMap = ({ defaultCenter, onLocationChange }) => {
    const { t } = useTranslation();
    const appId = useAppId();
    const markerIconUrl = `/projects/${appId}/images/marker.png`;
    const markerIcon = createMarkerIcon(markerIconUrl);

    const [currentCenter, setCurrentCenter] = useState(defaultCenter || { lat: 0, lng: 0 });

    useEffect(() => {
        if (!defaultCenter) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    const newCenter = { lat: latitude, lng: longitude };
                    setCurrentCenter(newCenter);
                },
                () => null
            );
        } else {
            setCurrentCenter(defaultCenter);
        }
    }, [defaultCenter]);


    const UpdateMapCenter = ({ center }) => {
        const map = useMap();
        useEffect(() => {
            map.flyTo(center, 18);
        }, [center, map]);

        map.whenReady(function() {
            map.on('dragend', function() {
                handleMoveEnd(map);
            });
        });

        return null;
    };

    const handleMoveEnd = (map) => {
        const newCenter = map.getCenter();
        const updatedCenter = { lat: newCenter.lat, lng: newCenter.lng };
        if (newCenter.lat !== currentCenter.lat || newCenter.lng !== currentCenter.lng) {
            setCurrentCenter(updatedCenter);
            onLocationChange(updatedCenter);
        }

    };

    return (
        <div className="address_block_map_map" style={{ height: '450px', width: '100%' }}>
            <MapContainer
                center={currentCenter}
                zoom={18}
                style={{ height: '100%', width: '100%' }}
                whenCreated={(map) => {
                    if (defaultCenter) {
                        map.setView(defaultCenter, 18);
                    }
                }}
                onMoveEnd={handleMoveEnd}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <UpdateMapCenter center={currentCenter}  />
            </MapContainer>
            <img  className="address_block_map_marker" src={markerIconUrl}  />
        </div>
    );
};

export default React.memo(AddressBlockMap);
