import React, { useState } from 'react';
import { useAppId } from "../AppIdContext";
import { handleLoginSuccess } from '../services/auth';
import { loginUser } from '../services/api';
import {useTranslation} from "react-i18next";

const LoginBlockMail = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const appId = useAppId();
    const {t} = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await loginUser(email, password, appId);
            const { token, name, code, message } = response;
            if (token) {
                handleLoginSuccess(token, name); // Redirect to splash screen or handle successful login
            } else if (code) {
                setError(message || t("login_block_mail_error"));
            }
        } catch (error) {
            setError(t("login_block_mail_error"));
        }
    };

    return (
        <div className="login_block_mail">
            <form onSubmit={handleSubmit}>
                <input
                    className="login_block_mail_input"
                    type="email"
                    placeholder={t("login_block_mail_input_email_hint")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    className="login_block_mail_input"
                    type="password"
                    placeholder={t("login_block_mail_input_password_hint")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" className="login_block_mail_button bold">{t("login_block_mail_button_text")}</button>
                {error && <div className="login_block_mail_error">{error}</div>}
            </form>
        </div>
    );
};

export default LoginBlockMail;
