import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {fetchOffers} from "../services/api";
import {useAppId} from "../AppIdContext";
import {useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";

const Product_catalog_block_offers = () => {


    const appId = useAppId();
    const [offers, setOffers] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const loadOffers = async () => {
            try {
                const loadedOffers = await fetchOffers(appId);
                setOffers(loadedOffers);
            } catch (error) {
                console.error('Failed to load items:', error);
            }
        };

        loadOffers();
    }, [appId]);


    if (offers == null ) {
        return (
            <Skeleton height={250}  className="product_catalog_block_offers_offer" />
        ); // Show a loading state while configuration or data is being loaded
    }

    const settings = {
        dots: offers.length > 1,
        infinite: offers.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="product_catalog_block_offers">
            <Slider {...settings}>
                {offers.map((offer, index) => (
                    <div key={index}>
                        <img className="product_catalog_block_offers_offer" src={offer.image[0].path} alt={`Slide ${index}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Product_catalog_block_offers;
