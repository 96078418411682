// src/components/login_block_facebook.js
import React, { useEffect } from 'react';
import { useAppId } from "../AppIdContext";
import { useTranslation } from "react-i18next";
import { registerUser } from '../services/api'; // Import the registerUser function
import { handleLoginSuccess } from '../services/auth';

const LoginBlockFacebook = ({config}) => {

    const appId = useAppId();
    const iconPath = `/projects/${appId}/images/login_block_social_facebook_icon.png`;
    const {t} = useTranslation();


    useEffect(() => {
        // Load the Facebook SDK
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: config.login_block_social_facebook_app_id, // Use the Facebook app ID from config
                cookie: true,
                xfbml: true,
                version: 'v10.0'
            });
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, [config.login_block_social_facebook_app_id]);

    const handleLogin = () => {
        window.FB.login(response => {
            if (response.authResponse) {
                window.FB.api('/me', { fields: 'name,email,picture' }, async (profile) => {
                    const { name, email, id } = profile;
                    try {
                        const platform = 'web'; // Assuming web platform
                        const registerResponse = await registerUser(name, email, null, appId, id, 'facebook', platform);
                        const { token } = registerResponse;
                        handleLoginSuccess(token, name);
                    } catch (error) {
                        console.error('Failed to register user:', error);
                    }
                });
            } else {
                console.error('User cancelled login or did not fully authorize.');
            }
        }, { scope: 'email,public_profile' });
    };


    return (
        <div className="login_block_facebook login_block_social_facebook_button login_block_social_button button" onClick={handleLogin}>
            <img className="login_block_social_facebook_icon login_block_social_button_icon button_icon button_icon"  src={iconPath} />
            <div className="login_block_social_facebook_button_text button_text button_text bold">{t("login_block_social_facebook_button_text")}</div>
        </div>
    );
};

export default LoginBlockFacebook;