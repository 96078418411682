import React, { useState, useEffect } from 'react';
import cart from '../services/cart';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppId } from "../AppIdContext";

const ProductCatalogBlockItemsItem = ({ config, item }) => {
    const appId = useAppId();
    const [price, setPrice] = useState(item.base_price);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (item.sale_price) {
            setPrice(item.sale_price);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.sale_price]);

    item.price = price;

    const itemQuantity = cart.getItemQuantity(item);
    const [inCart, setInCart] = useState(itemQuantity > 0);
    const [quantity, setQuantity] = useState(itemQuantity);

    const handleAddToCart = (item) => {
        if (item.field_item_variation_group && item.field_item_variation_group.length > 0 && config.product_catalog_block_items_item_clickable_enable) {
            handleItemClick();
        } else {
            setQuantity(1);
            const cartItem = {
                ...item,
                price: price,
                quantity: 1
            };
            setInCart(true);
            cart.addItem(cartItem);
        }
    };

    const handleItemClick = () => {
        if (config.product_catalog_block_items_item_clickable_enable) {
            navigate('/itemDetails', { state: { item: item, appId: appId } });
        }
    };

    const handleQuantityChange = (newQuantity) => {
        if (newQuantity <= 0) {
            cart.removeItem(item.id);
            setInCart(false);
        } else {
            cart.updateQuantity(item.id, newQuantity);
            setQuantity(newQuantity);
        }
    };


    const formatAvailability = (availability) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        const availabilityDate = new Date(availability.day);
        const availabilityTime = availability.time.replace(":", ".");

        if (availabilityDate.toDateString() === today.toDateString()) {
            return `available today ${availabilityTime}`;
        } else if (availabilityDate.toDateString() === tomorrow.toDateString()) {
            return `available tomorrow ${availabilityTime}`;
        } else {
            const formattedDate = `${String(availabilityDate.getDate()).padStart(2, '0')}/${String(availabilityDate.getMonth() + 1).padStart(2, '0')}/${availabilityDate.getFullYear()}`;
            return `${formattedDate} ${availabilityTime}`;
        }
    };



    const plus = `/projects/${appId}/images/plus_up.png`;
    const minus = `/projects/${appId}/images/minus_icon.png`;
    const addIcon = `/projects/${appId}/images/product_catalog_block_items_item_add_to_cart_icon.png`;

    return (
        <div key={item.id} className="product_catalog_block_items_item pointer" onClick={handleItemClick}>
            {item.item_image && item.item_image[0] ? (
                <img className="product_catalog_block_items_item_image" src={item.item_image[0].path} alt={item.name} />
            ) : (
                <div className="product_catalog_block_items_item_image_placeholder">No Image</div>
            )}
            <div className="product_catalog_block_items_item_info">
                <div className="product_catalog_block_items_item_name bold">{item.name}</div>
                {config.product_catalog_block_items_item_short_description_enable && (
                    <div className="product_catalog_block_items_item_short_description">{item.short_description}</div>
                )}
                <div className="product_catalog_block_items_item_price_tag flex">
                    {item.sale_price && (
                        <div className="product_catalog_block_items_item_old_price line-through bold">{item.base_price}</div>
                    )}
                    <div className="product_catalog_block_items_item_price bold">{item.sale_price}</div>
                </div>
                {config.product_catalog_block_items_item_availability_enable && item.availability && (
                    <div className="product_catalog_block_items_item_availability">
                        {formatAvailability(item.availability)}
                    </div>
                )}
            </div>
            {config.product_catalog_block_items_item_add_to_cart_enable && (
                !inCart ? (
                    <div className="product_catalog_block_items_item_add_to_cart button" onClick={(e) => { e.stopPropagation(); handleAddToCart(item); }}>
                        {config.product_catalog_block_items_item_add_to_cart_icon_enable && (
                            <img className="product_catalog_block_items_item_add_to_cart_icon button_icon" src={addIcon} />
                        )}
                        {config.product_catalog_block_items_item_add_to_cart_text_enable && (
                            <div className="product_catalog_block_items_item_add_to_cart_text button_text bold">{t("product_catalog_block_items_item_add_to_cart_text")}</div>
                        )}
                    </div>
                ) : (
                    <div className="product_catalog_block_items_item_quantity">
                        <span className="product_catalog_block_items_item_quantity_down" onClick={(e) => { e.stopPropagation(); handleQuantityChange(quantity - 1); }}><div className="product_catalog_block_items_item_quantity_down_icon">-</div></span>
                        <span className="product_catalog_block_items_item_quantity_text">{quantity}</span>
                        <span className="product_catalog_block_items_item_quantity_up" onClick={(e) => { e.stopPropagation(); handleQuantityChange(quantity + 1); }}><div className="product_catalog_block_items_item_quantity_up_icon">+</div></span>
                    </div>
                )
            )}
        </div>
    );
};

export default ProductCatalogBlockItemsItem;
